/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/slick-carousel@1.7.1/slick/slick-theme.min.css
 * - /npm/slick-carousel@1.7.1/slick/slick.min.css
 * - /npm/nanoscroller@0.8.7/bin/css/nanoscroller.min.css
 * - /npm/formstone@1.4.2/dist/css/themes/light.css
 * - /npm/formstone@1.4.18-1/dist/css/checkbox.min.css
 * - /npm/formstone@1.4.18-1/dist/css/dropdown.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
